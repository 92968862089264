export type Episode = {
    title: string,
    description: string,
    slug: string,
    full_slug: string,
    date: string,
    type: "full" | "trailer",
    duration: string
};

export const episodes: Episode[] = [
    {
        title: "Suspending the Campaign!",
        description: "We discuss watching the debate, what does success look like, going to Iowa, and tell one last story.",
        slug: "04-suspending-the-campaign",
        full_slug: "im-running-for-president-12-04_suspending-the-campaign",
        duration: "15:08",
        date: "2019-12-04T05:00:00.000Z",
        type: "full"
    },
    {
        title: "Platform Rollout",
        description: "We talk about what's weird about running for President, reading Hillary Clinton's mail, goats, and so much more.",
        slug: "03-platform-rollout",
        full_slug: "im-running-for-president-06-07_platform_rollout",
        duration: "26:21",
        date: "2019-06-07T05:00:00.000Z",
        type: "full"
    },
    {
        title: "I'm Official",
        description: "Akiva sets about becoming an Official Presidential Candidate. Paperwork and hijinks ensue. What does mom think?",
        slug: "02-im-official",
        full_slug: "im-running-for-president-04-15_im-official",
        duration: "20:31",
        date: "2019-04-15T05:00:00.000Z",
        type: "full"
    },
    {
        title: "Trailer",
        description: "Intro to I'm Running for President.",
        slug: "01-trailer",
        full_slug: "im-running-for-president-04-01_trailer",
        duration: "00:59",
        date: "2019-04-01T05:00:00.000Z",
        type: "trailer"
    }
];