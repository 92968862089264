import React from 'react';
import { Link } from 'react-router-dom';
import EmailValidator from 'email-validator';
import urls from '../urls';

type SignupPageState = {
    email: string,
    submitted: boolean
};

export class SignupPage extends React.Component<{}, SignupPageState> {

    constructor(props: {}) {
        super(props);

        this.state = {email: '', submitted: false};

        this.onEmailChanged = this.onEmailChanged.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onEmailChanged(e: React.FormEvent<HTMLInputElement>) {
        const email = (e.target as any).value || '';
        this.setState({email});
    }

    onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if(!EmailValidator.validate(this.state.email)) {
            return;
        }

        this.setState({submitted: true});

        fetch(process.env.REACT_APP_SIGNUP_URL || '', {
            method: 'POST',
            mode: 'no-cors',
            body: JSON.stringify({email: this.state.email}),
            headers: {
                'Content-Type': 'application/json'
            }
        });
    };

    render() {
        const submitEnabled = EmailValidator.validate(this.state.email);

        return <div className='vertical-center'>
            {!this.state.submitted
            ? (
                <form className="center" onSubmit={this.onSubmit}>
                    <h1 className="form-element">Join our list!</h1>
                    <p className="inline-container">Get updates on new episodes, dispatches from the road, prerelease content and more!</p>
                    <div className="labeled-element">
                        <label id="email-label" className="form-element">Email</label>
                        <input
                            aria-labelledby="email-label"
                            className="form-element" 
                            type="email"
                            onChange={this.onEmailChanged}
                            placeholder="you@example.com"
                        />
                    </div>
                    <input disabled={!submitEnabled} className="form-element" type="submit" value="Stay Informed"/>
                </form>
            ) : (
            <div className="center">
            <div className="inline-container center-content">
                <p className="form-element">Thanks! You'll hear from us soon!</p>
                <p className="form-element">In the mean time, listen to the <Link to={urls.podcast}>podcast</Link> or <a href={urls.donate}>donate</a>!</p>
            </div>
            </div>
        )}
        </div>
    }
}