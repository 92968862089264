import React from 'react';
import { Link } from 'react-router-dom';
import { Bullet } from '../widgets/Bullet';
import urls from '../urls';

export class PlatformPage extends React.Component {
    render() {
        return <div className="container">
                <div className="body-text">
                <h1>The Platform</h1>
                There's a lot going on in this country, but I've chosen to focus on three key issues:
                <div>
                    <h2>The Climate Crisis</h2>
                    <p>Climate change is real and an emergency. We need to approach climate change with the same focus and urgency as the space race. What would it take for us get to carbon neutral and how fast can we get there? And we can't just focus on America. The US is responsible for just 15% of total carbon emissions. We need to develop technologies and solutions that we can export all over the world. We need to transform how we think about energy and agriculture.</p>
                    <p>People talk about going slowly to protect the economy, but if we don't act there won't be an economy to protect. Would you rather have a new Manhattan Project or a Manhattan half buried in the ocean?</p>
                </div>
                <Bullet/>
                <div>
                    <h2>Taking on Big Tech</h2>
                    <p>I'm a software engineer by trade. I used to work in Silicon Valley. I know the big tech companies from the inside and they are bad for our society. Amazon is raking in revenue, and also violating antitrust law left and right. They use their control of online retail to bully competition out of business, and then they work their warehouse employees to the bone. Facebook has shown <a href="https://www.wired.com/story/why-zuckerberg-15-year-apology-tour-hasnt-fixed-facebook/">over and over</a> again it can't be trusted with the responsibility it has, not just with the spread of fake news, but in facilitating genocide in Myanmar.</p>
                    <p>Big tech wants to have its cake and eat it too—they want all the power to influence markets and individual behavior, without any of the responsibility for the consequences of that behavior. They wield immense power and are essentially dictatorships. They increasingly control our lives and they've shown they can't be trusted to act for the benefit of the people.</p>
                </div>
                <Bullet/>
                <div>
                    <h2>Immigration</h2>
                    <p>America is a nation of immigrants. My ancestors came to this country fleeing persecution and seeking a better life. We have a moral responsibility to offer the same opportunity today to the tired and the poor of the world. Immigrants should be treated humanely and welcomed to their new home. Our current government is violating the Geneva Convention and refusing asylum seekers when it should be offering them open arms.</p>
                    <p>We need an immigration policy designed to maximize the number of immigrants we take in, not minimize them. We need a clear, straightforward path to citizenship and policies that help people thrive once they're here. When individuals succeed, we all benefit.</p>
                </div>
                <Bullet/>
                <p>
                But there are so many things in America that could be better! What about some other important issue? See <Link to={`${urls.what}#positions`}>here</Link>.
                </p>
                </div>
        </div>
    }
}