import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';
import urls from './urls';

type Action = 
| { type: 'path', path: string, onClick: () => void }
| { type: 'external', path: string }
| { type: 'handler', onClick: () => void}

type HeaderItemProps = {
    title: string,
    action: Action,
    emphasis?: boolean
}

function HeaderItem(props: HeaderItemProps) {
    switch(props.action.type) {
        case 'external':
            return <a className={cx("header-item", {emphasis: props.emphasis})} href={props.action.path}>{props.title}</a>
        case 'path':
            return <Link className={cx("header-item", {emphasis: props.emphasis})} onClick={props.action.onClick} to={props.action.path}>{props.title}</Link>
        case 'handler':
            const onClick = props.action.onClick;
            return <button className={cx("header-item", {emphasis: props.emphasis})} onClick={e => {{e.preventDefault(); onClick();}}}>{props.title}</button>
    }
}

type HeaderProps = {} & RouteComponentProps<{}>;
type HeaderState = {
    menuVisible: boolean
};

class _Header extends React.Component<HeaderProps, HeaderState> {

    constructor(props: HeaderProps) {
        super(props);

        this.onMenuClick = this.onMenuClick.bind(this);
        this.onCloseMenu = this.onCloseMenu.bind(this);

        this.state = {menuVisible: false};
    }

    onMenuClick() {
        this.setState({menuVisible: true});
    }

    onCloseMenu() {
        this.setState({menuVisible: false});
    }

    render() {
        const options: (HeaderItemProps & {key: string})[] = [
            {
                title: 'Home',
                key: 'home',
                action: {type: 'path' , path: urls.home, onClick: this.onCloseMenu},
            },
            {
                title: 'Platform',
                key: 'platform',
                action: {type: 'path', path: urls.platform, onClick: this.onCloseMenu}
            },
            {
                title: 'Podcast',
                key: 'podcast',
                action: {type: 'path', path: urls.podcast, onClick: this.onCloseMenu}
            },
            {
                title: 'Store',
                key: 'store',
                action: {type: 'external', path: urls.store}
            },
            {
                title: 'What??',
                key: 'what',
                action: {type: 'path', path: urls.what, onClick: this.onCloseMenu}
            }
        ];
        const currentPath = this.props.location.pathname;
        const visibleOptions = options.filter(o => o.action.type !== 'path' || o.action.path !== currentPath);
        return (
            <header className="header big">
                    <CSSTransition in={this.state.menuVisible} timeout={200} classNames="fading" unmountOnExit>
                    <div className={"overlay"}>
                        <button className="close-overlay" onClick={this.onCloseMenu}>Close</button>
                        <div className="overlay-menu">
                        {visibleOptions.map(option => <HeaderItem {...option}/>)}
                        <HeaderItem emphasis={true} title='Donate' key='donate' action={{type: 'external', path: urls.donate}}/>
                        </div>

                    </div>
                    </CSSTransition>
                    <span className="header-group menu-items big">
                    {visibleOptions.map(option => <HeaderItem {...option}/>)}
                    </span>
                    <span className="header-group menu-items small">
                    <HeaderItem title='Menu' key='menu' action={{type: 'handler', onClick: this.onMenuClick}}/>
                    </span>
                    <span className="header-group">
                    <HeaderItem emphasis={true} title='Donate' key='donate' action={{type: 'external', path: urls.donate}}/>
                    </span>
            </header>
        );
    }
}

export const Header = withRouter(_Header);