import React from 'react';

function year() {
    return new Date().getFullYear();
}

type FooterProps = {};
export function Footer(props: FooterProps) {
    return <footer>
        <span className="disclaimer">Paid for by Akiva for the People</span>
        <hr></hr>
        <div className="contact"><a href="mailto:info@akivaforthepeople.com">Contact</a> | &copy;{year()} Akiva for the People</div>
        </footer>
}