import React from 'react';
import { Link } from 'react-router-dom';
import urls from './urls';

type Question = {
    key: string,
    q: string,
    a: React.ReactNode
}

export const questions: Question[] = [
    {
        key: "for-real",
        q: "Is this for real?",
        a: <>It's tongue in cheek, but yeah, I'm really running for president. Check me out on the <a href="https://www.fec.gov/data/candidate/P00010108/?tab=about-candidate">FEC's website</a>.</>
    },
    {
        key: "winning",
        q: "You're not gonna win.",
        a: <>That's not a question. But yes, I know that. Something very strange has happened in the reality in which I win. But I think the process of running for president will be really interesting. Don't you want to find out what happens? Subscribe to the <Link to={urls.podcast}>podcast</Link>. And if the American people do choose me to represent them, I would take that responsibility very seriously and give it my all.</>
    },
    {
        key: "positions",
        q: "What's your position on X or Y?",
        a: <>The <Link to={urls.platform}>platform page</Link> has a few key issues that I'm really focusing on, but I have stances on a number of issues. Here's a non-exhaustive list of some other stuff I'm into:
            <ul>
                <li>Reducing Income Inequality</li>
                <li>Universal Healthcare</li>
                <li>Tackling College Debt</li>
                <li>Universal Pre-K</li>
                <li>Stopping the Gun Violence Epidemic</li>
                <li>Ending the War on Drugs</li>
                <li>Choice in Reproductive Care</li>
                <li>Disability Rights are Civil Rights</li>
                <li>Crushing the Gender Binary</li>
                <li>Permanent Daylight Savings Time</li>
            </ul>
           </>
    }
]
