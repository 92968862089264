import React from 'react';
import { Episode, episodes } from '../episodes';
import { RouteComponentProps, Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Bullet } from '../widgets/Bullet';
import { url } from 'inspector';
import urls from '../urls';

type PodcastEpisodeProps = {
    episode: Episode,
    fullpage: boolean
};
function PodcastEpisode(props: PodcastEpisodeProps) {
    const preload = props.fullpage ? "auto" : "metadata";
    return <div className="episode">
            {props.fullpage ? <h1>{props.episode.title}</h1> : <h3>{props.episode.title}</h3>}
            <div>
                <audio controls preload={preload}>
                    <source src={`/p/${props.episode.full_slug}.mp3`} type="audio/mpeg"/>
                </audio>
                <div>
                {!props.fullpage ? <Link to={urls.episode(props.episode.slug)}><small>Notes and Transcript</small></Link> : null}
                </div>
            </div>
            {props.episode.description}
    </div>
}

export class PodcastPage extends React.Component {
    render() {
        return <div className="container">
                <div className="body-text">
                <h1>The Podcast</h1>

                <p>My name is Akiva and I’m running for president. I’m 35, I have bright blue hair, and I have no idea what I’m doing.</p>
                <p>Come along with me on my surely doomed presidential run. We’ll talk about issues, explore strange new state and federal bureaucracies, navigate the nomination process, and find out just what the heck you actually have to do to run for president. It’s gonna be great.</p>
                <p>So join the circus and subscribe to my new podcast <em>I’m Running for President</em>.</p>

                <Bullet/>

                Find it on:
                <ul>
                <li><a href="https://podcasts.apple.com/us/podcast/im-running-for-president/id1458832097">Apple Podcasts</a></li>
                <li><a href="https://playmusic.app.goo.gl/?ibi=com.google.PlayMusic&isi=691797987&ius=googleplaymusic&apn=com.google.android.music&link=https://play.google.com/music/m/Ivii7jt33qlpjfuh2sczpaqb47i?t%3DI'm_Running_for_President%26pcampaignid%3DMKT-na-all-co-pr-mu-pod-16">Google Play</a></li>
                <li><a href="https://open.spotify.com/show/1aUYRF4HoCXljRdRVXTmcR?si=T0FvGoBvRm-HBd7DWvz9uQ">Spotify</a></li>
                <li><a href="https://overcast.fm/itunes1458832097">Overcast</a></li>
                <li><a href="https://pca.st/824J">Pocketcasts</a></li>
                </ul>
                Or use the <a href="feed.xml">RSS feed</a>.
                <br/>
                <br/>
                <br/>
                <h2>Episodes</h2>
                <div className="episodes">
                    { episodes.map(episode => <PodcastEpisode key={episode.slug} episode={episode} fullpage={false}/>) }
                </div>
                </div>
        </div>
    }
}

type PodcastTranscriptPageProps = RouteComponentProps<{slug: string}>
type PodcastTranscriptPageState = {transcript: string | undefined};
export class PodcastTranscriptPage extends React.Component<PodcastTranscriptPageProps, PodcastTranscriptPageState> {

    constructor(props: PodcastTranscriptPageProps) {
        super(props);
        this.state = {transcript: undefined};
    }

    async componentDidMount() {
        const slug = this.props.match.params.slug;
        const episode = episodes.find(e => e.slug === slug);
        if(!episode) {
            return;
        }

        const transcriptPath = `/p/${episode.full_slug}.md`;
        const result = await fetch(transcriptPath);
        if(result.ok) {
            const transcript = await result.text();
            this.setState({transcript});
        }
    }

    render() {
        const slug = this.props.match.params.slug;
        const episode = episodes.find(e => e.slug === slug);
        return (episode
            ? <div className="container">
                <PodcastEpisode episode={episode} fullpage={true}/>
                {this.state.transcript
                    ? <div className="body-text">
                        <br/>
                        <ReactMarkdown source={this.state.transcript}/>
                    </div>
                    : null
                }
            </div>
            : <Redirect to="/"/>
        )
    }
}