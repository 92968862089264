import React, { Component } from 'react';
import './App.scss';
import { Header } from './Header';
import { Footer } from './Footer';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { MainPage } from './pages/MainPage';
import { FAQPage } from './pages/FAQPage';
import { SignupPage } from './pages/SignupPage';
import { PodcastPage, PodcastTranscriptPage } from './pages/PodcastPage';
import { PlatformPage } from './pages/PlatformPage';
import urls from './urls';

class App extends Component {
  render() {
    return (
      <div className="App">
      <BrowserRouter>
        <div className="content-container">
          <Header/>
          <main className='content-body vertical-center'>
            <Switch>
              <Route exact path={urls.home} component={MainPage}/>
              <Route exact path={urls.what} component={FAQPage}/>
              <Route exact path={urls.podcast} component={PodcastPage}/>
              <Route exact path={urls.platform} component={PlatformPage}/>
              <Route exact path={urls.signup} component={SignupPage}/>
              <Route exact path={urls.episode(":slug")} component={PodcastTranscriptPage}/>
              <Redirect path = "/*" to="/"/>
            </Switch>
          </main>
          <Footer/>
        </div>
      </BrowserRouter>
      </div>
    );
  }
}

export default App;
