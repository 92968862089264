import React from 'react';
import { Link } from 'react-router-dom';
import EmailValidator from 'email-validator';
import logo from '../images/logo.svg'
import urls from '../urls';

type MainPageState = {
    email: string,
    submitted: boolean
};

export class MainPage extends React.Component<{}, MainPageState> {

    constructor(props: {}) {
        super(props);

        this.state = {email: '', submitted: false};

        this.onEmailChanged = this.onEmailChanged.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onEmailChanged(e: React.FormEvent<HTMLInputElement>) {
        const email = (e.target as any).value || '';
        this.setState({email});
    }

    onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if(!EmailValidator.validate(this.state.email)) {
            return;
        }

        this.setState({submitted: true});

        fetch(process.env.REACT_APP_SIGNUP_URL || '', {
            method: 'POST',
            mode: 'no-cors',
            body: JSON.stringify({email: this.state.email}),
            headers: {
                'Content-Type': 'application/json'
            }
        });
    };

    render() {
        const submitEnabled = EmailValidator.validate(this.state.email);

        return <div>
            <div className="hero">
                <div className="bg-fill-left"/>
                <div className="bg-fill-right"/>
                <div className="image"/>
                <div className="gradient"/>
                <div className="filler"/>
                <div className="hero-text big">
                <img className="logo" src={logo}/><br/>for the People
                </div>
            </div>
            <div className="hero-text small">
            <img className="logo" src={logo}/> for the People
            </div>
            <div className="container">
                <div className="center">
                    <p>If <em>elected president</em>, Akiva will:</p>
                    <ul>
                        <li>Treat climate change like the crisis that it is</li>
                        <li>Break up the big tech companies</li>
                        <li>Establish an immigration policy the Statue of Liberty would be proud of</li>
                    </ul>
                    <p>Intrigued? Listen to the <Link to={urls.podcast}>podcast</Link></p>
                </div>
            </div>
            {!this.state.submitted
            ? (
            <div className="signup">
                <form className="center" onSubmit={this.onSubmit}>
                    <p className="form-element">Sign up to find out more!</p>
                    <div className="labeled-element">
                        <label id="email-label" className="form-element">Email</label>
                        <input
                            aria-labelledby="email-label"
                            className="form-element" 
                            type="email"
                            onChange={this.onEmailChanged}
                            placeholder="you@example.com"
                        />
                    </div>
                    <input disabled={!submitEnabled} className="form-element" type="submit" value="Stay Informed"/>
                </form>
            </div>
            ) : (
            <div className="signup">
            <div className="center">
                <p className="form-element">Thanks! You'll hear from us soon!</p>
            </div>
            </div>
        )}
        </div>
    }
}