import React from 'react';
import { questions } from '../faq';

export class FAQPage extends React.Component {
    render() {
        return <div className="container">
                <div className="body-text">
                <h1>What the heck?</h1>
                <h2>Frequently asked questions</h2>
                <dl className='faq'>{
                    questions.map(i => {
                        return (
                            <React.Fragment key={i.key}>
                                <dt className="question">{i.q}<a id={i.key}/></dt>
                                <dd className="answer">{i.a}</dd>
                            </React.Fragment>
                        )
                    })
                }</dl>
            </div>
        </div>
    }
}